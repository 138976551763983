import { useEffect, useRef, useState } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import axios from 'axios'
import ScrollToTop from './utils/ScrollToTop'
import Header from './components/header/Header'
import MainPages from './pages/MainPages'
import DocumentsPages from './pages/DocumentsPages'
import Loader from './components/loader/Loader' // Импортируем Loader

import './styles/main.css'

function App() {
	const userEid = localStorage.getItem('user_eid')

	const [pageColor, setPageColor] = useState('#ce1d7f') // Цвет страницы по умолчанию (розовый)
	const [loading, setLoading] = useState(true) // Состояние загрузки
	const hasFetched = useRef(false)

	useEffect(() => {
		const getDocuments = async () => {
			try {
				setLoading(true) // Показываем прелоадер перед началом запроса
				const savedColor = localStorage.getItem('page_color')
				if (savedColor) {
					// Если есть цвет в localStorage, применяем его и не делаем запрос к API
					setPageColor(savedColor)
					document.documentElement.style.setProperty(
						'--primary-pink',
						savedColor
					)
					setLoading(false) // Останавливаем прелоадер
					return // Завершаем выполнение
				} else {
					const response = await axios.get(
						`${process.env.REACT_APP_HOST}/api/page-color/`,
						{
							auth: {
								username: process.env.REACT_APP_LOGIN,
								password: process.env.REACT_APP_PASSWORD,
							},
						}
					)
					console.log('Цвет страницы:', response.data)

					let color = '#ce1d7f' // По умолчанию розовый цвет

					if (response.data.page_color === 'red') {
						color = '#ce1d7f'
					} else if (response.data.page_color === 'green') {
						color = '#118642'
					}

					// Обновляем цвет страницы
					setPageColor(color)

					// Сохраняем цвет в localStorage для последующих рендеров
					localStorage.setItem('page_color', color)

					// Обновляем цвет в CSS переменной
					document.documentElement.style.setProperty('--primary-pink', color)
				}
			} catch (error) {
				console.error('Error fetching documents:', error)
			} finally {
				setLoading(false) // Скрываем прелоадер после завершения запроса
			}
		}

		// Выполняем запрос только один раз
		if (!hasFetched.current) {
			getDocuments()
			hasFetched.current = true
		}
	}, [])

	const publicRoutes = (
		<>
			<Route path='/' element={<MainPages pageColor={pageColor} />} />
			<Route
				path='/documents'
				element={<DocumentsPages pageColor={pageColor} />}
			/>
		</>
	)

	const privateRoutes = <></>

	return (
		<Router>
			<ScrollToTop />
			<div className='App'>
				<Header pageColor={pageColor} />
				<div className='content'>
					{loading ? ( // Условно отображаем прелоадер или контент
						<Loader />
					) : (
						<Routes>
							{publicRoutes}
							{userEid ? (
								privateRoutes
							) : (
								<>
									<Route path='*' element={<MainPages to='/' />} />
									<Route
										path='/documents'
										element={<DocumentsPages to='documents' />}
									/>
								</>
							)}
						</Routes>
					)}
				</div>
			</div>
		</Router>
	)
}

export default App

import React, { useState, useEffect } from 'react'

import Advantages from '../components/advantages/Advantages'
import Loans from '../components/loans/Loans'
import Calculator from '../components/calculator/Calculator'
import Reviews from '../components/reviews/Reviews'
import Contact from '../components/contact/Contact'
import Questions from '../components/questions/Questions'
import Footer from '../components/footer/Footer'
import Cases from '../components/cases/Cases'
import TopBGPink from '../img/background/TopBG.webp'
import TopBGGreen from '../img/background/TopBGGreen.webp'
// import TopBGDarkMode from "../img/background/TopBGDarkMode.webp";
import TopBGMobilePink from '../img/background/TopBGMobile.webp'
import TopBGMobileGreen from '../img/background/TopBGMobileGreen.webp'

const MainPages = ({ pageColor }) => {
	const [isMobileResolution, setMobileResolution] = useState(false)

	const DesktopTopBG = pageColor === '#118642' ? TopBGGreen : TopBGPink
	const MobileTopBG =
		pageColor === '#118642' ? TopBGMobileGreen : TopBGMobilePink

	const handleResize = () => {
		setMobileResolution(window.innerWidth <= 567)
	}

	useEffect(() => {
		window.addEventListener('resize', handleResize)
		handleResize()
		return () => window.removeEventListener('resize', handleResize)
	}, [])

	return (
		<>
			<img
				src={isMobileResolution ? MobileTopBG : DesktopTopBG}
				alt=''
				className='top-background'
			/>
			<Advantages pageColor={pageColor} />
			<Loans />
			<Calculator pageColor={pageColor} />
			<Cases />
			<Reviews pageColor={pageColor} />
			<Contact pageColor={pageColor} />
			<Questions />
			<Footer />
		</>
	)
}

export default MainPages

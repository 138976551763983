import { useState } from 'react'

import Modal from '../modal/Modal.jsx'

import './advantages.css'

import DragonImg from './AdvantagesDragon.webp'

import TelephoneLogoPink from './TelephoneLogo.webp'
import TelephoneLogoGreen from './TelephoneLogoGreen.webp'
import SMSLogoPink from './SMSLogo.webp'
import SMSLogoGreen from './SMSLogoGreen.webp'

const Advantages = ({ pageColor }) => {
	const TelephoneLogo =
		pageColor === '#118642' ? TelephoneLogoGreen : TelephoneLogoPink
	const SMSLogo = pageColor === '#118642' ? SMSLogoGreen : SMSLogoPink

	const [isButtonDisabled, setIsButtonDisabled] = useState(false)
	const [isModalOpen, setModalOpen] = useState(false) // состояние для открытия/закрытия модального окна

	const handleCloseModal = () => {
		setModalOpen(false) // закрываем модальное окно
	}
	const handleOpenModal = () => {
		setModalOpen(true)
	}

	return (
		<section className='advantages' id='advantages'>
			<div className='advantages__container'>
				<div className='advantages__main'>
					<div className='advantages__main__text'>
						<h1 className='advantages__main-title'>
							<div className='advantages__span'></div>
							<span>Займ</span> под залог <br />
							недвижимости <br /> и авто
						</h1>
						<h3 className='advantages__subtitle'>
							От 50.000 до 20.000.000 рублей <br /> С любой кредитной историей
						</h3>
						<button
							className={`advantages__button ${
								isButtonDisabled ? 'disabled' : ''
							}`}
							onClick={handleOpenModal}
							disabled={isButtonDisabled}
						>
							Получить займ
						</button>
					</div>
					<img src={DragonImg} alt='' className='advantages__img-dragon' />
				</div>
				<div className='advantages__info'>
					<div className='advantages__online'>
						<img
							src={TelephoneLogo}
							alt=''
							className='advantages__telephone-logo'
						/>
						<div className='advantages__explanation'>
							<h3 className='advantages__subtitle advantages-heading'>
								Быстрое оформление
							</h3>
							<p className='advantages__desc'>
								Получите решение за 15 минут <br /> Минимум документов
							</p>
						</div>
					</div>

					<div className='advantages__reliably'>
						<img src={SMSLogo} alt='' className='advantages__sms-logo' />
						<div className='advantages__explanation'>
							<h3 className='advantages__subtitle advantages-heading'>
								С нами надежно
							</h3>
							<p className='advantages__desc'>
								Входим в реестр Центрального банка РФ: запись № 2303357009980
							</p>
						</div>
					</div>
				</div>
			</div>
			<Modal
				isOpen={isModalOpen}
				onClose={handleCloseModal}
				pageColor={pageColor}
			/>
		</section>
	)
}

export default Advantages

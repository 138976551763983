import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    user_eid: "",
};

// Создаем слайс для сохранения user_eid
const userEIdSlice = createSlice({
    name: "user_eid",
    initialState,
    reducers: {
        setId: (state, action) => {
            state.user_eid = action.payload;
        },
    },
});

export const { setId } = userEIdSlice.actions;
export default userEIdSlice.reducer;

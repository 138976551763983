import { useState, useEffect } from "react";

const useMobileResize = () => {
    const [isMobileResolution, setMobileResolution] = useState(false);

    const handleResize = () => {
        setMobileResolution(window.innerWidth <= 567);
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        handleResize(); // Set the initial state based on current window size
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return isMobileResolution;
};

export default useMobileResize;

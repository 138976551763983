import { useState, useRef, useEffect } from 'react'
import { NavLink, useLocation } from 'react-router-dom'

import Modal from '../modal/Modal'

import './header.css'

import HeaderLogoPink from './header-logo.webp'
import HeaderLogoGreen from './header-logo-green.png'

function scrollToCenterByIds(ids) {
	ids.forEach(id => {
		const element = document.getElementById(id)
		if (element) {
			const elementRect = element.getBoundingClientRect()
			const offset = (window.innerHeight - elementRect.height) / 2
			window.scrollTo({
				top: elementRect.top + window.pageYOffset - offset,
				behavior: 'smooth',
			})
		}
	})
}

const ids = [
	'advantages',
	'loans',
	'calculator',
	'reviews',
	'contact',
	'questions',
]

function ScrollToCenter() {
	scrollToCenterByIds(ids)
}

const Header = ({ pageColor }) => {
	const location = useLocation()
	const listRef = useRef(null)

	const handleLinkClick = (e, id) => {
		e.preventDefault()
		scrollToCenterByIds([id])

		const target = e.currentTarget
		if (!target) return // Проверка наличия target

		const itemWidth = target.offsetWidth // Место, где может возникнуть ошибка
		if (!listRef.current) return // Проверка наличия listRef

		const listScrollLeft = listRef.current.scrollLeft
		const listWidth = listRef.current.clientWidth
		const targetPosition = target.offsetLeft

		// Логика прокрутки
		if (targetPosition <= listScrollLeft + 40) {
			listRef.current.scrollTo({
				top: 0,
				left: 0,
				behavior: 'smooth',
			})
		} else if (targetPosition + itemWidth > listScrollLeft + listWidth - 10) {
			listRef.current.scrollBy({
				top: 0,
				left: targetPosition + itemWidth - (listScrollLeft + listWidth) + 30,
				behavior: 'smooth',
			})
		}
	}

	const [isModalOpen, setModalOpen] = useState(false) // состояние для открытия/закрытия модального окна

	const handleCloseModal = () => {
		setModalOpen(false) // закрываем модальное окно
	}
	const handleOpenModal = () => {
		setModalOpen(true)
	}

	const logo = pageColor === '#118642' ? HeaderLogoGreen : HeaderLogoPink

	return (
		<header className='header'>
			<div className='header__container'>
				<div className='header__main'>
					<div className='header__link'>
						<NavLink to='/' className='header__logo header__logo_desktop'>
							<div className='header__logo__wrapper'>
								<img src={logo} alt='' className='header__logo-img' />
								<h3 className='header__subtitle'>МКК Флиббертак</h3>
							</div>
						</NavLink>
					</div>
					<div className='header__right'>
						<a href='tel:8 (342) 270-02-82' className='header__desc'>
							8 (342) 270-02-82
						</a>
						<button onClick={handleOpenModal} className='header__button-loan'>
							Получить займ
						</button>
					</div>
				</div>
				{location.pathname === '/' && (
					<nav className='header__nav'>
						<ul className='header__list' ref={listRef}>
							<li className='header__list-item'>
								<a
									href='#advantages'
									className='header__text'
									onClick={e => handleLinkClick(e, 'advantages')}
								>
									Преимущества
								</a>
							</li>
							<li className='header__list-item'>
								<a
									href='#loans'
									className='header__text'
									onClick={e => handleLinkClick(e, 'loans')}
								>
									Займы
								</a>
							</li>
							<li className='header__list-item'>
								<a
									href='#calculator'
									className='header__text'
									onClick={e => handleLinkClick(e, 'calculator')}
								>
									Калькулятор
								</a>
							</li>
							<li className='header__list-item'>
								<a
									href='#reviews'
									className='header__text'
									onClick={e => handleLinkClick(e, 'reviews')}
								>
									Отзывы
								</a>
							</li>
							<li className='header__list-item'>
								<a
									href='#contact'
									className='header__text'
									onClick={e => handleLinkClick(e, 'contact')}
								>
									Обращение
								</a>
							</li>
							<li className='header__list-item'>
								<a
									href='#questions'
									className='header__text'
									onClick={e => handleLinkClick(e, 'questions')}
								>
									Частые вопросы
								</a>
							</li>
						</ul>
					</nav>
				)}
			</div>
			<Modal
				isOpen={isModalOpen}
				onClose={handleCloseModal}
				pageColor={pageColor}
			/>
		</header>
	)
}

export default Header

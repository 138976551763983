import './cases.css'
import Case1 from '../../img/icons/case1.svg'
import Case2 from '../../img/icons/case2.svg'
import Case3 from '../../img/icons/case3.svg'
import Case4 from '../../img/icons/case4.svg'
import Case5 from '../../img/icons/case5.svg'
import Case6 from '../../img/icons/case6.svg'

const Cases = () => {
	return (
		<section className='cases'>
			<div className='cases__container'>
				<h2 className='cases__title'>
					Помогаем даже <br /> в самых сложных случаях
				</h2>
				<div className='cases__wrapper'>
					<div className='cases__row'>
						<div className='cases__item item-cases'>
							<div className='item-cases__img'>
								<img src={Case1} alt='' />
							</div>
							<h3 className='item-cases__text'>
								Если испорчена <br /> кредитная история
							</h3>
						</div>
						<div className='cases__item item-cases'>
							<div className='item-cases__img'>
								<img src={Case6} alt='' />
							</div>
							<h3 className='item-cases__text'>
								Есть финансовая нагрузка, но срочно нужны деньги
							</h3>
						</div>
					</div>
					<div className='cases__row'>
						<div className='cases__item item-cases'>
							<div className='item-cases__img'>
								<img src={Case2} alt='' />
							</div>
							<h3 className='item-cases__text'>
								Есть просрочки, долги, аресты
							</h3>
						</div>
						<div className='cases__item item-cases'>
							<div className='item-cases__img'>
								<img src={Case5} alt='' />
							</div>
							<h3 className='item-cases__text'>Везде отказывают</h3>
						</div>
					</div>
					<div className='cases__row'>
						<div className='cases__item item-cases'>
							<div className='item-cases__img'>
								<img src={Case3} alt='' />
							</div>
							<h3 className='item-cases__text'>
								Деньги нужны срочно, <br /> прямо здесь и сейчас
							</h3>
						</div>

						<div className='cases__item item-cases'>
							<div className='item-cases__img'>
								<img src={Case4} alt='' />
							</div>
							<h3 className='item-cases__text'>
								Не одобряют <br /> большую сумму
							</h3>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}

export default Cases

import React, { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";

import Modal from "../modal/Modal";

import "./calculator.css";

import CalculatorMoneyLightMode from "./CalculatorMoney-LightMode.webp";
// import CalculatorMoneyDarkMode from "./CalculatorMoney-DarkMode.webp";
import RublePink from "../../img/icons/rublePink.svg";
import RubleGreen from "../../img/icons/rubleGreen.svg";
import RubleWhite from "../../img/icons/rubleWhite.svg";
import ERRORPINK from "../../img/icons/ERROR.svg";
import ERRORGREEN from "../../img/icons/ERRORGREEN.svg";

import CalculatorBGPink from "../../img/background/CalculatorBG.webp";
import CalculatorBGGreen from "../../img/background/CalculatorBGGreen.webp";
import TabletCalculatorBGPink from "../../img/background/CalculatorBG-Tablet.webp";
import TabletCalculatorBGGreen from "../../img/background/CalculatorBGGreen-Tablet.webp";
import MobileCalculatorBGPink from "../../img/background/CalculatorBG-Mobile.webp";
import MobileCalculatorBGGreen from "../../img/background/CalculatorBGGreen-Mobile.webp";

const termArray = [1, 3, 6, 12, 24, 36, 48, 60];

const Calculator = ({ pageColor }) => {
    const [loanAmount, setLoanAmount] = useState(200000);
    const [loanTerm, setLoanTerm] = useState(4);
    const [monthlyPayment, setMonthlyPayment] = useState("");
    const [error, setError] = useState("");

    const [isModalOpen, setModalOpen] = useState(false); // состояние для открытия/закрытия модального окна

    const Ruble = pageColor === "#118642" ? RubleGreen : RublePink;
    const ERROR = pageColor === "#118642" ? ERRORGREEN : ERRORPINK;

    const DesktopCalculatorBG =
        pageColor === "#118642" ? CalculatorBGGreen : CalculatorBGPink;
    const TabletCalculatorBG =
        pageColor === "#118642"
            ? TabletCalculatorBGGreen
            : TabletCalculatorBGPink;
    const MobileCalculatorBG =
        pageColor === "#118642"
            ? MobileCalculatorBGGreen
            : MobileCalculatorBGPink;

    const [isTabletResolution, setIsTabletResolution] = useState(false);
    const [isMobileResolution, setMobileResolution] = useState(false);

    const handleResize = () => {
        const width = window.innerWidth;
        setIsTabletResolution(width <= 1025);
        setMobileResolution(width <= 578);
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const handleCloseModal = () => {
        setModalOpen(false); // закрываем модальное окно
    };
    const handleOpenModal = () => {
        setModalOpen(true);
    };

    const loanAmountRef = useRef(null);
    const loanTermRef = useRef(null);

    const LoanAmountChange = (event) => {
        const inputElement = event.target;
        const rawValue = inputElement.value;

        const selectionStart = inputElement.selectionStart;

        let value = rawValue.replace(/\D/g, "");

        if (value === "") {
            setLoanAmount("");
            setError("");
            inputElement.value = "";
            return;
        }

        if (value < 50000) {
            setError("Не меньше 50 000");
        } else if (value > 20000000) {
            setError("Не больше 20 000 000");
        } else {
            setError(""); // Очищаем ошибку, если значение в пределах допустимого диапазона
        }

        let formattedValue = formatAmount(value);

        let cursorPosition = selectionStart;

        if (formattedValue.length > rawValue.length) {
            cursorPosition += formattedValue.length - rawValue.length;
        } else if (formattedValue.length < rawValue.length) {
            cursorPosition -= rawValue.length - formattedValue.length;
        }

        if (
            cursorPosition > 0 &&
            formattedValue[cursorPosition - 1] == " " &&
            event.nativeEvent.inputType == "deleteContentBackward"
        ) {
            cursorPosition -= 1;
        }

        setLoanAmount(Number(value));

        setTimeout(() => {
            inputElement.value = formattedValue;
            cursorPosition = Math.max(0, cursorPosition);
            inputElement.setSelectionRange(cursorPosition, cursorPosition);
        }, 0);
    };

    const handleSliderChange = (event) => {
        const value = Number(event.target.value);
        setLoanAmount(value);

        // Очищаем ошибку при изменении значения ползунка, если значение допустимо
        if (value >= 50000 && value <= 20000000) {
            setError("");
        }
    };

    const formatAmount = (num) => {
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
    };

    const LoanTermChange = (event) => {
        setLoanTerm(event.target.value);
    };

    const calculateMonthlyPayment = () => {
        const C = loanAmount; // Сумма кредита
        const S = termArray[loanTerm]; // Срок кредита в месяцах
        const annualInterestRate = 0.8; // 80% годовых
        const M = annualInterestRate / 12; // Месячная процентная ставка

        const K = (M * Math.pow(1 + M, S)) / (Math.pow(1 + M, S) - 1); // Коэффициент аннуитета
        const X = C * K; // Аннуитетный платеж

        return Math.round(X);
    };

    useEffect(() => {
        const payment = calculateMonthlyPayment();
        setMonthlyPayment(payment);
    }, [loanAmount, loanTerm]);

    useEffect(() => {
        const updateBackground = (rangeInput) => {
            const value =
                ((rangeInput.value - rangeInput.min) /
                    (rangeInput.max - rangeInput.min)) *
                100;
            rangeInput.style.background = `linear-gradient(to right, var(--primary-pink) ${value}%, #a4a4a4 ${value}%)`;
        };
        const rangeInputs = document.querySelectorAll(".range_box");
        rangeInputs.forEach((input) => {
            updateBackground(input);
            input.addEventListener("input", () => updateBackground(input));
        });
        return () => {
            rangeInputs.forEach((input) => {
                input.removeEventListener("input", () =>
                    updateBackground(input)
                );
            });
        };
    }, [loanAmount, loanTerm]);

    useEffect(() => {
        const updateWidth = (inputRef) => {
            if (inputRef.current) {
                const tempElement = document.createElement("span");
                tempElement.style.visibility = "hidden";
                tempElement.style.position = "absolute";
                tempElement.style.whiteSpace = "nowrap";
                tempElement.style.fontFamily = getComputedStyle(
                    inputRef.current
                ).fontFamily;
                tempElement.style.fontSize = getComputedStyle(
                    inputRef.current
                ).fontSize;
                tempElement.textContent =
                    inputRef.current.value || inputRef.current.placeholder;
                document.body.appendChild(tempElement);

                const { width } = tempElement.getBoundingClientRect();
                document.body.removeChild(tempElement);
                inputRef.current.style.width = `${width + 11}px`;
            }
        };

        updateWidth(loanAmountRef);
        updateWidth(loanTermRef);
    }, [loanAmount, loanTerm]);

    const AmountError = () => {
        if (!error) return null;
        return (
            <div className="calculator__error">
                <img src={ERROR} alt="" className="calculator__img-error" />
                <p className="calculator__remark">{error}</p>
                <img src={Ruble} alt="" className="calculator__img-ruble" />
            </div>
        );
    };

    return (
        <section className="calculator" id="calculator">
            <div className="calculator__container">
                <h2 className="calculator__title">Калькулятор займов</h2>
                <div className="calculator__center">
                    <div className="calculator__wrapper">
                        <img
                            src={CalculatorMoneyLightMode}
                            alt=""
                            className="calculator__img-money-light"
                        />
                        <div className="calculator__main">
                            <div className="calculator__sum">
                                <div className="calculator__explanation">
                                    <p className="calculator__text">
                                        Сумма кредита
                                    </p>
                                    <div className="calculator__info">
                                        <input
                                            type="tel"
                                            autoComplete="off"
                                            spellCheck="false"
                                            inputMode="decimal"
                                            pattern="[0-9\s]*"
                                            className="calculator__text calculator__input"
                                            maxLength={10}
                                            value={formatAmount(loanAmount)}
                                            onChange={LoanAmountChange}
                                            ref={loanAmountRef}
                                        />
                                        <img
                                            src={Ruble}
                                            alt=""
                                            className="calculator__img"
                                        />
                                    </div>
                                </div>
                                <input
                                    type="range"
                                    className="range_box"
                                    min="50000"
                                    max="20000000"
                                    step="50000"
                                    value={loanAmount}
                                    onChange={handleSliderChange} // Используем новый обработчик
                                />
                                <AmountError />
                            </div>

                            <div className="calculator__term">
                                <div className="calculator__explanation">
                                    <p className="calculator__text">Срок</p>
                                    <div className="calculator__info">
                                        <input
                                            type="text"
                                            className="calculator__text calculator__input"
                                            disabled
                                            contentEditable="true"
                                            value={termArray[loanTerm]}
                                            onChange={LoanTermChange}
                                            ref={loanTermRef}
                                        />
                                        <p className="calculator__text loan_amount">
                                            мес
                                        </p>
                                    </div>
                                </div>
                                <input
                                    type="range"
                                    className="range_box"
                                    min="0"
                                    max={termArray.length - 1}
                                    step="1"
                                    value={loanTerm}
                                    onChange={LoanTermChange}
                                    list="terms"
                                />
                            </div>
                        </div>
                    </div>
                    <NavLink
                        state={{ loanAmount, loanTerm: termArray[loanTerm] }}
                        className="calculator__button"
                    >
                        <button onClick={handleOpenModal}>Получить</button>
                    </NavLink>
                </div>
            </div>
            <div className="calculator__monthly-payment">
                <img
                    className="calculator__monthly-payment-img"
                    src={
                        isMobileResolution
                            ? MobileCalculatorBG
                            : isTabletResolution
                            ? TabletCalculatorBG
                            : DesktopCalculatorBG
                    }
                    alt=""
                />
                <div className="calculator__explanation-payment">
                    <p className="calculator__desc payment__desc">
                        Ежемесячный платёж
                    </p>
                    <div className="calculator__info">
                        <h3 className="calculator__subtitle calculate-payment">
                            {formatAmount(monthlyPayment)}
                        </h3>
                        <img
                            src={RubleWhite}
                            alt=""
                            className="calculator__img-payment"
                        />
                    </div>
                </div>
            </div>
            <Modal
                isOpen={isModalOpen}
                onClose={handleCloseModal}
                pageColor={pageColor}
            />
        </section>
    );
};

export default Calculator;

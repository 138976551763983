import { useEffect, useRef, useState } from "react";

import axios from "axios";

import { Swiper, SwiperSlide } from "swiper/react";

import { createStars } from "../../helpers/ReviewsHelpers";
import { EffectCards } from "swiper/modules";

import "swiper/css";
import "swiper/css/navigation";

import ArrowPrevPink from "../../img/icons/BlogSliderArrow.svg";
import ArrowNextPink from "../../img/icons/BlogSliderArrow.svg";
import ArrowPrevGreen from "../../img/icons/BlogSliderArrowGreen.svg";
import ArrowNextGreen from "../../img/icons/BlogSliderArrowGreen.svg";

const MobileSlider = ({ pageColor }) => {
    const isGreen = pageColor === "#118642" ? true : false;
    const ArrowPrev = pageColor === "#118642" ? ArrowPrevGreen : ArrowPrevPink;
    const ArrowNext = pageColor === "#118642" ? ArrowNextGreen : ArrowNextPink;
    const swiperRef = useRef(null);
    const [isLastSlide, setIsLastSlide] = useState(false);
    const [isFirstSlide, setIsFirstSlide] = useState(true);

    const [reviews, setReviews] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const handlePrevClick = () => {
        if (swiperRef.current) {
            swiperRef.current.swiper.slidePrev();
        }
    };

    const handleNextClick = () => {
        if (swiperRef.current) {
            swiperRef.current.swiper.slideNext();
        }
    };

    useEffect(() => {
        const swiper = swiperRef.current.swiper;

        const updateSlideStatus = () => {
            setIsLastSlide(swiper.isEnd);
            setIsFirstSlide(swiper.isBeginning);
        };

        swiper.on("slideChange", updateSlideStatus);
        return () => {
            swiper.off("slideChange", updateSlideStatus);
        };
    }, []);

    useEffect(() => {
        const getReviews = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_HOST}/api/reviews`,
                    {
                        auth: {
                            username: process.env.REACT_APP_LOGIN,
                            password: process.env.REACT_APP_PASSWORD,
                        },
                    }
                );

                const data = response.data;
                const reviews = [];
                if (data.YNDX) {
                    let arr = data.YNDX;
                    arr.forEach((element) => {
                        reviews.push(element);
                    });
                }
                if (data.GIS) {
                    let arr = data.GIS;
                    arr.forEach((element) => {
                        reviews.push(element);
                    });
                }
                setReviews(reviews);
            } catch (error) {
                console.error("Error fetching documents:", error);
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        getReviews();
    }, []);

    return (
        <div className="slider-container mobile-slider">
            <Swiper
                ref={swiperRef}
                effect={"cards"}
                grabCursor={true}
                centeredSlides
                modules={[EffectCards]}
                initialSlide={2}
                className="mySwiper"
            >
                {reviews.map((item, i) => {
                    return (
                        <SwiperSlide key={i}>
                            <div className="reviews__item item-reviews">
                                <div className="item-reviews__header">
                                    <p className="item-reviews__name item-reviews__desc">
                                        {item.reviewer_name}
                                    </p>
                                    <div className="item-reviews__rating">
                                        {createStars(item.stars, isGreen)}
                                    </div>
                                </div>
                                <div className="item-reviews__content">
                                    <p className="item-reviews__comment item-reviews__text">
                                        {item.content}
                                    </p>
                                </div>
                                <div className="item-reviews__bottom">
                                    <p className="item-reviews__date item-reviews__desc">
                                        {item.date}
                                    </p>
                                    <p className="item-reviews__src item-reviews__desc">
                                        {item.review_source}
                                    </p>
                                </div>
                            </div>
                        </SwiperSlide>
                    );
                })}
            </Swiper>
            <div className="slider-navigation slider-navigation__reviews">
                <div
                    className="slider-button slider-button-prev"
                    onClick={handlePrevClick}
                    style={{ opacity: isFirstSlide ? 0.5 : 1 }}
                >
                    <img src={ArrowPrev} alt="" />
                </div>
                <div
                    className="slider-button slider-button-next"
                    onClick={handleNextClick}
                    style={{ opacity: isLastSlide ? 0.5 : 1 }}
                >
                    <img src={ArrowNext} alt="" />
                </div>
            </div>
        </div>
    );
};

export default MobileSlider;

import Phone from '../img/icons/phone.webp'
import Mail from '../img/icons/mail.webp'
import Clock from '../img/icons/clock.webp'
import Booklet from '../img/icons/booklet.webp'
import Location from '../img/icons/location.webp'

const mapInfo = [
	{
		icon: Location,
		text: 'Пермский край, г. Пермь, ул. Куйбышева, д.95б, офис 1201/2',
	},
	{
		icon: Phone,
		text: '8 (342) 270-02-82',
		link: 'tel:8 (342) 270-02-82',
	},
	{
		icon: Mail,
		text: 'info@flibbertak.ru',
		link: 'mailto:info@flibbertak.ru',
	},
	{
		icon: Clock,
		text: 'С 09:00 до 19:00 Пн-Пт',
	},
	{
		icon: Booklet,
		text: 'РАБОТАЕМ ПО ВСЕЙ РОССИИ',
	},
]

const footerLinks = [{ name: 'Документы', link: '/documents' }]

export { mapInfo, footerLinks }

import Star from "../img/icons/star.webp";
import GreenStar from "../img/icons/star-green.webp"; // Импортируем зеленую звезду

const createStars = (rating, isGreen = false) => {
    let stars = [];
    for (let i = 0; i < rating; i++) {
        stars.push(
            <img
                src={isGreen ? GreenStar : Star} // Если isGreen === true, используем зеленую звезду, иначе обычную
                className="reviews__star"
                alt=""
                key={i}
            />
        );
    }
    return stars;
};

export { createStars };

import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import './documents.css'

const Documents = () => {
	const navigate = useNavigate()
	const [documents, setDocuments] = useState({}) // Состояние для хранения документов

	const handleBackClick = () => {
		navigate('/')
	}

	useEffect(() => {
		const getDocuments = async () => {
			try {
				const response = await axios.get(
					`${process.env.REACT_APP_HOST}/api/documents`,
					{
						auth: {
							username: process.env.REACT_APP_LOGIN,
							password: process.env.REACT_APP_PASSWORD,
						},
					}
				)
				setDocuments(response.data)
			} catch (error) {
				console.error('Error fetching documents:', error)
			}
		}

		getDocuments()
	}, [])

	return (
		<section className='documents'>
			<div className='documents__container'>
				<div className='documents__heading'>
					<div className='documents__main' onClick={handleBackClick}>
						<div className='arrow-left'></div>
						<p className='documents__text documents__home'>Главная</p>
					</div>
					<h2 className='documents__title'>Документы</h2>
				</div>

				<div className='documents__wrapper'>
					{Object.keys(documents).map((category, index) => (
						<div key={index} className='documents__item item-documents'>
							<p className='item-documents__desc '>{category}</p>{' '}
							<ul className='item-documents__list'>
								{documents[category].map(doc => (
									<li key={doc.doc_id} className='item-documents__item'>
										<a
											href={`${process.env.REACT_APP_HOST}${doc.doc_url}`} // Преобразование относительной ссылки в абсолютную
											className='documents__text'
											target='_blank'
											rel='noopener noreferrer'
										>
											{doc.doc_name}
										</a>
									</li>
								))}
							</ul>
						</div>
					))}
				</div>
			</div>
		</section>
	)
}

export default Documents

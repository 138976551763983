import { configureStore } from "@reduxjs/toolkit";
import registrationReducer from "./features/registrationSlice";
import userEIdSlice from "./features/userEIdSlice";

export const store = configureStore({
    reducer: {
        registration: registrationReducer,
        user_eid: userEIdSlice,
    },
});

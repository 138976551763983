import './loans.css'

import LoansHome from '../../img/icons/LoansHome.webp'
import LoansCar from '../../img/icons/LoansCar.webp'
import LoansFactoring from '../../img/icons/LoansFactoring.webp'
import LoansRefinancing from '../../img/icons/LoansRefinancing.webp'
import LoansHomeDarkMode from '../../img/icons/LoansHomeDarkMode.png'
import LoansCarDarkMode from '../../img/icons/LoansCarDarkMode.png'
import LoansFactoringDarkMode from '../../img/icons/LoansFactoringDarkMode.png'
import LoansRefinancingDarkMode from '../../img/icons/LoansRefinancingDarkMode.png'

const Loans = () => {
	return (
		<section className='loans' id='loans'>
			<div className='loans__container'>
				<h2 className='loans__title'>Займы</h2>
				<div className='loans__info'>
					<div className='loans__pledge'>
						<div className='loans__real-estate'>
							<img src={LoansHome} alt='' className='loans__home-logo' />
							<div className='loans__explanation'>
								<h3 className='loans__subtitle loans-heading'>
									Под залог недвижимости
								</h3>
								<p className='loans__desc'>
									<span className='break'>Жилая/нежилая/коммерческая </span>
									недвижимость, земельные участки
								</p>
							</div>
						</div>
						<div className='loans__motor-transport'>
							<img src={LoansCar} alt='' className='loans__car-logo' />
							<div className='loans__explanation'>
								<h3 className='loans__subtitle loans-heading'>
									Под залог автотранспорта
								</h3>
								<p className='loans__desc'>
									Легковые/грузовые автомобили, спецтехника
								</p>
							</div>
						</div>
					</div>
					<div className='loans__terms'>
						<div className='loans__factoring'>
							<img
								src={LoansFactoring}
								alt=''
								className='loans__factoring-logo'
							/>
							<div className='loans__explanation'>
								<h3 className='loans__subtitle loans-heading'>Факторинг</h3>
								<p className='loans__desc'>
									<span className='heading'>Залог</span> — Ваше денежное
									требование <br />
									<span className='break'>
										<span className='heading'>Цели</span> — Для закрытия
										кассовых разрывов, На пополнение оборотных средств
									</span>
								</p>
							</div>
						</div>
						<div className='loans__refinancing'>
							<img
								src={LoansRefinancing}
								alt=''
								className='loans__refinancing-logo'
							/>
							<div className='loans__explanation'>
								<h3 className='loans__subtitle loans-heading'>
									Рефинансирование
								</h3>
								<p className='loans__desc'>
									<span className='break'>Жилая/нежилая/коммерческая </span>
									недвижимость, автотранспорт, спецтехника
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}

export default Loans

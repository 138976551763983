const accordionQuestions = [
  {
    title:
      "Какова минимальная и максимальная процентная ставка, под которую предоставляются микрозаймы?",
    text: `Полная стоимость потребительского займа от 12% годовых до 292% годовых*.
Процентная ставка от 12% годовых до 292% годовых*.\n\r\n
*для отдельных категорий потребительских займов максимальная процентная ставка дополнительно ограничена законом
`,
  },
  {
    title:
      "На какой минимальный и максимальный срок предоставляются микрозаймы?",
    text: `Минимальный и максимальный срок, на который предоставляются микрозаймы, составляет от 1 года до 10 лет соответственно. В индивидуальных случаях срок, на который предоставляется микрозайм, может быть как меньше, так и больше указанных пределов.`,
  },
  {
    title:
      "Можно ли посмотреть пример расчета ежемесячных платежей по договору микрозайма?",
    text: `Расчет зависит от выбранной заемщиком методики начисления процентов и расчета платежей в соответствии с разделом 9 общих условий договора микрозайма.
Допустим, заемщиком была выбрана методика "Процентный платеж".\n\r\n
Сумма займа, планируемого к получению, составляет 100 000 рублей.
Срок, на который заемщик собирается взять микрозайм, составляет 15 месяцев.
Процентная ставка равна 80 % годовых.\n\r\n
При указанных условиях размер ежемесячных платежей, за исключением пятнадцатого платежа, составит: 100000*80/12/100=6666,66 рублей. Пятнадцатый платеж составит 100 000 + 6 666,66 = 106 666,66 рублей. Итого сумма денежных средств к возврату к концу срока предоставления микрозайма составит 6 666,66 * 14 + 106 666,66 = 200 000 рублей.
`,
  },
  {
    title:
      "Какая неустойка предусмотрена за несвоевременный возврат суммы микрозайма?",
    text: `По договорам потребительского микрозайма - 1/365 от 20 (двадцати) процентов годовых, начисляемых на просроченную задолженность по договору микрозайма за каждый день просрочки.\n\r\n
    По договорам коммерческого микрозайма - 0,3 (Ноль целых три десятых) процента в день, начисляемых на задолженность по договору микрозайма за каждый день ненадлежащего исполнения обязательств по договору микрозайма.\n\r\n
    Конкретный размер неустойки и порядок ее расчета устанавливается в индивидуальных условиях договора микрозайма.`,
  },
];

export { accordionQuestions };

import Documents from '../components/documents/Documents'
import Footer from '../components/footer/Footer'
import useMobileResize from '../utils/useMobileResize'

import PersonalAccountImg from '../img/background/PersonalAccountImg.webp'
import PersonalAccountMobileImg from '../img/background/TopBG-Mobile.webp'
import PersonalAccountImgGreen from '../img/background/PersonalAccountImgGreen.webp'
import PersonalAccountMobileImgGreen from '../img/background/PersonalAccountMobileImgGreen.webp'

const DocumentsPages = ({ pageColor }) => {
	const isMobileResolution = useMobileResize()

	const DesktopTopBG =
		pageColor === '#118642' ? PersonalAccountImgGreen : PersonalAccountImg
	const MobileTopBG =
		pageColor === '#118642'
			? PersonalAccountMobileImgGreen
			: PersonalAccountMobileImg

	return (
		<>
			<img
				src={isMobileResolution ? MobileTopBG : DesktopTopBG}
				alt=''
				className='personal-account__img'
			/>
			<Documents pageColor={pageColor} />
			<Footer pageColor={pageColor} />
		</>
	)
}

export default DocumentsPages

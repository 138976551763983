import { accordionQuestions } from "../../helpers/QuestionsHelpers";
import { useState } from "react";
import "./questions.css";

const Questions = () => {
  const [selectedItems, setSelectedItems] = useState([]);

  const toggleAccordion = (item) => {
    // Проверяем, открыт ли элемент
    if (selectedItems.includes(item)) {
      // Если открыт, закрываем его, удаляя из массива
      setSelectedItems(selectedItems.filter((i) => i !== item));
    } else {
      // Если закрыт, открываем его, добавляя в массив
      setSelectedItems([...selectedItems, item]);
    }
  };

  const formatText = (text) => {
    // Разбиваем текст на строки по специальным символам
    const lines = text.split(/\n|\r\n/);
    // Возвращаем текст с переносами строк
    return lines.map((line, index) => <p key={index}>{line}</p>);
  };

  return (
    <div className="questions" id="questions">
      <div className="questions__background">
        <div className="questions__container">
          <h2 className="questions__title">Частые вопросы</h2>
          <div className="questions__wrapper">
            <div className="questions__accordion accordion-questions">
              {accordionQuestions.map((item, i) => {
                return (
                  <div
                    key={item.title}
                    className={`accordion-questions__item ${
                      selectedItems.includes(i) ? "active" : ""
                    }`}
                  >
                    <div
                      onClick={() => toggleAccordion(i)}
                      className="accordion-questions-subtitle"
                    >
                      <h3 className="accordion__subtitle">{item.title}</h3>
                      <span
                        className={
                          selectedItems.includes(i) ? "plus" : "plus active"
                        }
                      ></span>
                    </div>
                    <div
                      className={
                        selectedItems.includes(i)
                          ? "accordion-questions__desc active"
                          : "accordion-questions__desc"
                      }
                    >
                      {formatText(item.text)}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Questions;

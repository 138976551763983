import React, { useEffect, useState } from "react";
import InputMask from "react-input-mask";
import axios from "axios";
import "./modal.css";

import ClosePink from "../../img/icons/close-pink.svg";
import CloseGreen from "../../img/icons/CloseGreen.svg";

import HeaderLogoPink from "./header-logo.webp";
import HeaderLogoGreen from "./header-logo-green.png";

import AdjustPink from "../../img/icons/adjust-pink.svg";
import AdjustGreen from "../../img/icons/adjust-green.svg";

const Modal = ({ isOpen, onClose, pageColor }) => {
    const Close = pageColor === "#118642" ? CloseGreen : ClosePink;
    const logo = pageColor === "#118642" ? HeaderLogoGreen : HeaderLogoPink;
    const Adjust = pageColor === "#118642" ? AdjustGreen : AdjustPink;

    const [isVisible, setIsVisible] = useState(false);
    const [selectedAsset, setSelectedAsset] = useState(null);
    const [showAssetList, setShowAssetList] = useState(false);
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [loanAmount, setLoanAmount] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);

    useEffect(() => {
        if (isOpen) {
            setIsVisible(true);
            setShowAssetList(false);
            setIsSubmitted(false); // Сбрасываем сообщение при открытии модального окна
        }
    }, [isOpen]);

    const handleAssetClick = () => {
        setShowAssetList(!showAssetList);
    };

    const handleAssetSelect = (asset) => {
        setSelectedAsset(asset);
        setShowAssetList(false);
    };

    // Функция для проверки корректности номера телефона
    const isPhoneValid = () => {
        // Убираем символы, кроме цифр
        const digits = phone.replace(/\D/g, "");
        return digits.length === 11; // Проверяем, что ровно 11 цифр
    };

    const isFormValid = () => {
        return name && isPhoneValid() && loanAmount && selectedAsset;
    };

    const color = pageColor === "#118642" ? "green" : "red";
    const sendForm = async () => {
        setIsSubmitting(true);
        try {
            function getUTMParams() {
                const urlParams = new URLSearchParams(window.location.search); // получаем параметры запроса из URL
                const utmParams = {};

                // Ищем UTM метки среди всех параметров
                urlParams.forEach((value, key) => {
                    if (key.startsWith("utm_")) {
                        utmParams[key] = value; // сохраняем UTM параметры
                    }
                });

                return utmParams;
            }

            // Вы можете вызвать функцию и вывести результат
            console.log("utm", getUTMParams());
            let utms = getUTMParams();
            let send_utm = ""
            if(utms){
                send_utm = `?utm_source=${utms.utm_source}&utm_medium=${utms.utm_medium}&utm_term=${utms.utm_term}&utm_content=${utms.utm_content}&utm_campaign=${utms.utm_campaign}`;
            }
            console.log(send_utm)
            await axios.put(
                `${
                    process.env.REACT_APP_HOST
                }/api_main/backcall/${send_utm}`,
                {
                    name: name,
                    phone: phone,
                    amount: loanAmount,
                    property: selectedAsset,
                    page_colour: color,
                },
                {
                    auth: {
                        username: process.env.REACT_APP_LOGIN,
                        password: process.env.REACT_APP_PASSWORD,
                    },
                }
            );
            console.log(
                "Данные успешно отправлены",
                name,
                phone,
                loanAmount,
                selectedAsset,
                color
            );
            setIsSubmitted(true); // Устанавливаем сообщение об успешной отправке
        } catch (error) {
            console.error("Ошибка при отправке данных:", error);
        } finally {
            setIsSubmitting(false);
        }
    };

    if (!isVisible) return null;

    return (
        <div className={`modal ${isOpen ? "modal-open" : ""}`}>
            <div className="modal__content">
                <div className="header__main">
                    <div className="header__link">
                        <div className="header__logo__wrapper">
                            <img
                                src={logo}
                                alt=""
                                className="header__logo-img"
                            />
                            <h3 className="header__subtitle">МКК Флиббертак</h3>
                        </div>
                    </div>
                </div>
                <h3 className="modal__subtitle">Получить займ</h3>
                <div className="modal__wrapper">
                    <div className="modal__header">
                        <span className="close" onClick={onClose}>
                            <img src={Close} alt="" />
                        </span>
                    </div>
                    {isSubmitted && (
                        <p className="modal__success-message">
                            Ваша заявка успешно отправлена, мы свяжемся с вами в
                            ближайшее время
                        </p>
                    )}
                    {!isSubmitted && (
                        <div className="modal__body">
                            <div className="modal__fields">
                                <div className="modal__field">
                                    <p className="modal__desc">Ваше имя</p>
                                    <input
                                        type="text"
                                        className="modal__input modal__desc"
                                        required
                                        value={name}
                                        onChange={(e) =>
                                            setName(e.target.value)
                                        }
                                    />
                                </div>
                                <div className="modal__field">
                                    <p className="modal__desc">
                                        Номер телефона
                                    </p>
                                    <InputMask
                                        placeholder="+7 (999) 999-99-99"
                                        mask="+7 (999) 999-99-99"
                                        className="modal__input modal__desc"
                                        required
                                        value={phone}
                                        onChange={(e) =>
                                            setPhone(e.target.value)
                                        }
                                    />
                                    {!isPhoneValid() && (
                                        <p className="modal__remark">
                                            Введите корректный номер телефона
                                            (11 цифр).
                                        </p>
                                    )}
                                </div>
                                <div className="modal__field">
                                    <p className="modal__desc">
                                        Сумма займа (₽)
                                    </p>
                                    <input
                                        type="tel"
                                        className="modal__input modal__desc"
                                        required
                                        value={loanAmount}
                                        onChange={(e) =>
                                            setLoanAmount(e.target.value)
                                        }
                                    />
                                </div>
                                <div className="modal__field ">
                                    <p className="modal__desc">
                                        Выберите тип имущества, которым вы
                                        владеете
                                    </p>
                                    <div
                                        className="modal__field-asset"
                                        onClick={handleAssetClick}
                                    >
                                        <input
                                            type="text"
                                            className="modal__input modal__desc modal__input-asset"
                                            readOnly
                                            required
                                            value={selectedAsset || ""}
                                        />
                                        <img
                                            className="modal__icon"
                                            src={Adjust}
                                            alt=""
                                        />
                                    </div>
                                    {showAssetList && (
                                        <div className="asset-list">
                                            <div className="asset-list__header">
                                                <h3 className="asset-list__desc">
                                                    Тип имущества
                                                </h3>
                                                <span className="asset-list__close">
                                                    <img
                                                        src={Close}
                                                        onClick={
                                                            handleAssetClick
                                                        }
                                                        alt=""
                                                    />
                                                </span>
                                            </div>
                                            <ul className="asset-list__list">
                                                <li>
                                                    <label className="custom-radio">
                                                        <input
                                                            type="radio"
                                                            name="property"
                                                            value="Автомобиль"
                                                            onClick={() =>
                                                                handleAssetSelect(
                                                                    "Автомобиль"
                                                                )
                                                            }
                                                            checked={
                                                                selectedAsset ===
                                                                "Автомобиль"
                                                            }
                                                        />
                                                        <span className="modal__text">
                                                            Автомобиль
                                                        </span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="custom-radio">
                                                        <input
                                                            type="radio"
                                                            name="property"
                                                            value="Квартира"
                                                            onClick={() =>
                                                                handleAssetSelect(
                                                                    "Квартира"
                                                                )
                                                            }
                                                            checked={
                                                                selectedAsset ===
                                                                "Квартира"
                                                            }
                                                        />
                                                        <span className="modal__text">
                                                            Квартира
                                                        </span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="custom-radio">
                                                        <input
                                                            type="radio"
                                                            name="property"
                                                            value="Комната"
                                                            onClick={() =>
                                                                handleAssetSelect(
                                                                    "Комната"
                                                                )
                                                            }
                                                            checked={
                                                                selectedAsset ===
                                                                "Комната"
                                                            }
                                                        />
                                                        <span className="modal__text">
                                                            Комната
                                                        </span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="custom-radio">
                                                        <input
                                                            type="radio"
                                                            name="property"
                                                            value="Дом"
                                                            onClick={() =>
                                                                handleAssetSelect(
                                                                    "Дом"
                                                                )
                                                            }
                                                            checked={
                                                                selectedAsset ===
                                                                "Дом"
                                                            }
                                                        />
                                                        <span className="modal__text">
                                                            Дом
                                                        </span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="custom-radio">
                                                        <input
                                                            type="radio"
                                                            name="property"
                                                            value="Земельный участок"
                                                            onClick={() =>
                                                                handleAssetSelect(
                                                                    "Земельный участок"
                                                                )
                                                            }
                                                            checked={
                                                                selectedAsset ===
                                                                "Земельный участок"
                                                            }
                                                        />
                                                        <span className="modal__text">
                                                            Земельный участок
                                                        </span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="custom-radio">
                                                        <input
                                                            type="radio"
                                                            name="property"
                                                            value="Спецтехника (бульдозеры, самосвалы, экскаваторы и др)"
                                                            onClick={() =>
                                                                handleAssetSelect(
                                                                    "Спецтехника (бульдозеры, самосвалы, экскаваторы и др)"
                                                                )
                                                            }
                                                            checked={
                                                                selectedAsset ===
                                                                "Спецтехника (бульдозеры, самосвалы, экскаваторы и др)"
                                                            }
                                                        />
                                                        <span className="modal__text">
                                                            Спецтехника
                                                            (бульдозеры,
                                                            самосвалы,
                                                            экскаваторы и др)
                                                        </span>
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <button
                                onClick={sendForm}
                                className="modal__button"
                                disabled={!isFormValid() || isSubmitting}
                            >
                                {isSubmitting ? "Отправка..." : "Получить займ"}
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Modal;

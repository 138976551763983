import { useState, useEffect } from 'react'
import axios from 'axios'
import './contact.css'

import BottomBGPink from '../../img/background/BottomBG.webp'
import BottomBGGreen from '../../img/background/BottomBGGreen.webp'
import ContactLetterPink from './contact-letter.webp'
import ContactLetterGreen from './contact-letterGreen.webp'

import ContactBGPink from '../../img/background/ContactBG.webp'
import ContactBGGreen from '../../img/background/ContactBGGreen.webp'
import TabletContactBGPink from '../../img/background/ContactBG-Tablet.webp'
import TabletContactBGGreen from '../../img/background/ContactBGGreen-Tablet.webp'
import MobileContactBGPink from '../../img/background/ContactBG-Mobile.webp'
import MobileContactBGGreen from '../../img/background/ContactBGGreen-Mobile.webp'

const Contact = ({ pageColor }) => {
	const BottomBG = pageColor === '#118642' ? BottomBGGreen : BottomBGPink
	const ContactLetter =
		pageColor === '#118642' ? ContactLetterGreen : ContactLetterPink
	const DesktopContactBG =
		pageColor === '#118642' ? ContactBGGreen : ContactBGPink
	const TabletContactBG =
		pageColor === '#118642' ? TabletContactBGGreen : TabletContactBGPink
	const MobileContactBG =
		pageColor === '#118642' ? MobileContactBGGreen : MobileContactBGPink

	const [isTabletResolution, setIsTabletResolution] = useState(false)
	const [isMobileResolution, setMobileResolution] = useState(false)

	const handleResize = () => {
		const width = window.innerWidth
		setIsTabletResolution(width <= 1025)
		setMobileResolution(width <= 578)
	}

	useEffect(() => {
		window.addEventListener('resize', handleResize)
		handleResize()
		return () => window.removeEventListener('resize', handleResize)
	}, [])

	const [fullName, setFullName] = useState('')
	const [phone, setPhone] = useState('')
	const [comment, setComment] = useState('')
	const [errors, setErrors] = useState({
		fullName: false,
		phone: false,
		comment: false,
	})

	// Обработчик для изменения значения полей
	const handleChange = (field, value) => {
        // Обновление значений полей
        if (field === 'fullName') setFullName(value)
        if (field === 'phone') setPhone(value)
        if (field === 'comment') setComment(value)
    
        // Если поле заполнилось, убираем ошибку, кроме телефона с длиной меньше 11
        if (field !== 'phone' && value.trim()) {
            setErrors(prevErrors => ({
                ...prevErrors,
                [field]: false,
            }))
        } else if (field === 'phone' && value.trim().length === 11) {
            setErrors(prevErrors => ({
                ...prevErrors,
                [field]: false,
            }))
        }
    }
    
    // Обработчик для события blur (потеря фокуса)
    const handleBlur = (field) => {
        // Если поле пустое или телефон меньше 11 символов, устанавливаем ошибку
        if ((field === 'fullName' && !fullName.trim()) ||
            (field === 'phone' && phone.trim().length < 11) ||
            (field === 'comment' && !comment.trim())) {
            setErrors(prevErrors => ({
                ...prevErrors,
                [field]: true,
            }))
        }
    }

	// Отправка обращения
	// const sendRequest = async () => {
	// 	// Проверка всех полей перед отправкой
	// 	const newErrors = {
	// 		fullName: !fullName.trim(),
	// 		phone: !phone.trim(),
	// 		comment: !comment.trim(),
	// 	}
	// 	setErrors(newErrors)

	// 	// Если хотя бы одно поле не заполнено, прекратить выполнение
	// 	if (Object.values(newErrors).some(error => error)) {
	// 		return
	// 	}

	// 	try {
	// 		const response = await axios.post(
	// 			`${process.env.REACT_APP_HOST}/api_main/lk/backcall/without-user/`,
	// 			{
	// 				phone:phone,
	// 				comment: comment,
	// 				full_name: fullName,
	// 			},
	// 			{
	// 				auth: {
	// 					username: process.env.REACT_APP_LOGIN,
	// 					password: process.env.REACT_APP_PASSWORD,
	// 				},
	// 			}
	// 		)

	// 		// Очищаем поля формы после успешной отправки
	// 		setFullName('')
	// 		setPhone('')
	// 		setComment('')
	// 		setErrors({ fullName: false, phone: false, comment: false }) // Сбрасываем ошибки

	// 		alert('Обращение успешно отправлено')
	// 	} catch (error) {
	// 		console.log('Ошибка отправки обращения:', error)
	// 	}
	// }

    const sendRequest = async () => {
	// Проверка всех полей перед отправкой
	const newErrors = {
		fullName: !fullName.trim(),
		phone: phone.trim().length !== 11,  // Проверка, что длина телефона равна 11 символам
		comment: !comment.trim(),
	}
	setErrors(newErrors)

	// Если хотя бы одно поле не заполнено или телефон некорректен, прекратить выполнение
	if (Object.values(newErrors).some(error => error)) {
		return
	}

	try {
		const response = await axios.post(
			`${process.env.REACT_APP_HOST}/api_main/lk/backcall/without-user/`,
			{
				phone,
				comment,
				full_name: fullName,
			},
			{
				auth: {
					username: process.env.REACT_APP_LOGIN,
					password: process.env.REACT_APP_PASSWORD,
				},
			}
		)
		
		// Отправка события отправки формы в Яндекс.Метрику
		if (window.ym) {
            window.ym(97976977, 'reachGoal', 'form');
        }
		// Очищаем поля формы после успешной отправки
		setFullName('')
		setPhone('')
		setComment('')
		setErrors({ fullName: false, phone: false, comment: false }) // Сбрасываем ошибки

		alert('Обращение успешно отправлено')
	} catch (error) {
		console.log('Ошибка отправки обращения:', error)
	}
}
	return (
		<section className='contact' id='contact'>
			<img src={BottomBG} className='contact-bottom-background' alt='' />
			<div className='contact__container'>
				<h2 className='contact__title'>Форма обращения</h2>
				<div className='contact__wrapper'>
					<div className='contact__form form-contact'>
						<form className='form-contact__content'>
							<div className='form-contact-top top-form'>
								<div className='top-form__wrapper'>
                                <input
									className={`form-contact__field form-contact__input form-contact__text ${errors.fullName ? 'required' : ''}`}
									type='text'
									placeholder='ФИО'
									value={fullName}
									onChange={e => handleChange('fullName', e.target.value)}
									onBlur={() => handleBlur('fullName')}
									required
								/>
                                	{errors.fullName && <p className="error__remark">Обязательное поле</p>}
                                    <input
                                    className={`form-contact__field form-contact__input form-contact__text ${errors.phone ? 'required' : ''}`}
                                    type='tel'
                                    placeholder='Номер телефона'
                                    value={phone}
                                    minLength={11}  // Минимальная длина для телефона
                                    maxLength={11}  // Максимальная длина для телефона
                                    onChange={e => handleChange('phone', e.target.value)}
                                    onBlur={() => handleBlur('phone')}
                                    required
                                />
                                   {errors.phone && <p className="error__remark">Введите корректный номер телефона (11 цифр)</p>}
								</div>
								<img src={ContactLetter} alt='' className='top-form__img' />
							</div>
							<textarea
							className={`form-contact__field form-contact__textarea form-contact__text ${errors.comment ? 'required' : ''}`}
							placeholder='Текст сообщения'
							value={comment}
							onChange={e => handleChange('comment', e.target.value)}
							onBlur={() => handleBlur('comment')}
							required
							></textarea>
                        	{errors.comment && <p className="error__remark">Обязательное поле</p>}
						</form>
						<button className='form-contact__button' onClick={sendRequest}>
							Отправить
						</button>
					</div>
				</div>
			</div>
			<div className='contact__bg bg-contact'>
				<img
					className='bg-contact__img'
					src={
						isMobileResolution
							? MobileContactBG
							: isTabletResolution
							? TabletContactBG
							: DesktopContactBG
					}
					alt=''
				/>
				<p className='bg-contact__desc'>
					Направить претензию/жалобу/иное обращение в АО МКК “ФТК”
				</p>
			</div>
		</section>
	)
}

export default Contact

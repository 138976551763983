import "./reviews.css";

import DesktopSlider from "./DesktopSlider";
import MobileSlider from "./MobileSlider";
const Reviews = ({ pageColor }) => {
    return (
        <section className="reviews" id="reviews">
            <div className="reviews__background">
                <div className="reviews__container">
                    <h2 className="reviews__title">Отзывы</h2>
                    <DesktopSlider pageColor={pageColor} />
                    <MobileSlider pageColor={pageColor} />
                </div>
            </div>
        </section>
    );
};
export default Reviews;

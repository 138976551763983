import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    code: "",
};

// Создаем слайс для регистрации
const registrationSlice = createSlice({
    name: "registration",
    initialState,
    reducers: {
        setCode: (state, action) => {
            state.code = action.payload;
        },
    },
});

export const { setCode } = registrationSlice.actions;
export default registrationSlice.reducer;

import { NavLink } from "react-router-dom";

import "./footer.css";

import { footerLinks, mapInfo } from "../../helpers/FooterHelpers";

import FooterLogo from "./footer-logo.webp";

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer__background">
                <div className="footer__container">
                    <div className="footer__wrapper">
                        <div className="footer__top top-footer">
                            <div className="footer__nav nav-footer">
                                <NavLink to="/" className="nav-footer__text">
                                    <div className="nav-footer__logo">
                                        <img src={FooterLogo} alt="" />
                                    </div>
                                </NavLink>
                                <ul className="nav-footer__list">
                                    {footerLinks.map((item) => {
                                        return (
                                            <li
                                                key={item.name}
                                                className="nav-footer__list-item  "
                                            >
                                                <NavLink
                                                    to={item.link}
                                                    className="nav-footer__text"
                                                >
                                                    {item.name}
                                                </NavLink>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        </div>
                        <div className="footer__map map-footer">
                            <div className="map-footer__map">
                            <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3Aa616cceba8060f7f17a524f047b606ae85860e53ea7eff85c3677d5150697ca4&amp;source=constructor"  frameborder="0"></iframe>
                            </div>
                            <ul className="map-footer__info">
                                {mapInfo.map((item) => {
                                    return (
                                        <li
                                            key={item.text}
                                            className="map-footer__item"
                                        >
                                            <div className="map-footer__icon">
                                                <img src={item.icon} alt="" />
                                            </div>
                                            <a
                                                href={`${
                                                    item.link
                                                        ? item.link
                                                        : "#footer"
                                                }`}
                                                className="map-footer__text"
                                            >
                                                {item.text}
                                            </a>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                        <div className="footer__bottom bottom-footer">
                            <p className="bottom-footer__text">
                                АКЦИОНЕРНОЕ ОБЩЕСТВО МИКРОКРЕДИТНАЯ КОМПАНИЯ
                                "ФЛИББЕРТАК" (АО МКК «ФТК»); ИНН: 5904406758;
                                ОГРН: 1235900012028; КПП: 590401001;
                            </p>
                            <p className="bottom-footer__text">
                                Юридический адрес: 614010, Пермский край, г.
                                Пермь, ул. Куйбышева, д. 95б, офис
                                1201/2.Официальный сайт Банка России:
                                https://www.cbr.ru; страница сайта Банка России,
                                содержащая государственный реестр
                                микрофинансовых организаций:
                                https://www.cbr.ru/vfs/finmarkets/files/supervision/list_MFO.xlsx;
                                дата внесения сведений о юридическом лице в
                                государственный реестр микрофинансовых
                                организаций: 22.08.2023; АО МКК «ФТК» является
                                членом саморегулируемой организации в сфере
                                финансового рынка, объединяющей микрофинансовые
                                организации; дата вступления в саморегулируемую
                                организацию: 06.09.2023; Регистрационный номер,
                                присвоенный в реестре членов саморегулируемой
                                организации в сфере финансового рынка: 09 23 033
                                59 2109; наименование саморегулируемой
                                организации: СОЮЗ "МИКРОФИНАНСОВЫЙ АЛЬЯНС
                                "ИНСТИТУТЫ РАЗВИТИЯ МАЛОГО И СРЕДНЕГО БИЗНЕСА";
                                адрес (местонахождение) центрального офиса
                                саморегулируемой организации: 125367, РОССИЯ, Г.
                                МОСКВА, ВН.ТЕР.Г. МУНИЦИПАЛЬНЫЙ ОКРУГ
                                ПОКРОВСКОЕ-СТРЕШНЕВО, ПОЛЕССКИЙ ПР-Д, Д. 16,
                                СТР. 1, ПОМЕЩ./ЭТ. 308/АНТРЕСОЛЬ; адрес
                                (местонахождение) обособленного подразделения
                                саморегулируемой организации: 420111, г. Казань,
                                ул. Профсоюзная, д. 34; адрес официального сайта
                                саморегулируемой организации в сети Интернет:
                                https://alliance-mfo.ru; ссылка на страницу
                                интернет-приемной Банка России на сайте Банка
                                России: https://www.cbr.ru/reception/;
                                потребители финансовых услуг вправе направить
                                обращение финансовому уполномоченному в
                                соответствии со статьями 15 - 19 Федерального
                                закона от 4 июня 2018 года N 123-ФЗ "Об
                                уполномоченном по правам потребителей финансовых
                                услуг" (Собрание законодательства Российской
                                Федерации, 2018, N 24, ст. 3390), место
                                нахождения службы обеспечения деятельности
                                финансового уполномоченного: 119017, г. Москва,
                                Старомонетный пер., дом 3; почтовый адрес службы
                                обеспечения деятельности финансового
                                уполномоченного: 119017, г. Москва,
                                Старомонетный пер., дом 3, получатель АНО
                                «СОДФУ», адрес официального сайта финансового
                                уполномоченного в
                                информационно-телекоммуникационной сети
                                "Интернет": https://finombudsman.ru
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
